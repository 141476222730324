import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const ServicesPage = () => (
    <Layout pageInfo={{ pageName: "services" }}>
        <SEO title="Services" />
        <h1>Services</h1>
        <p>CoinMarketMood is powered by a general purpose sentiment analysis platform that we built for the task. We are an experienced team of software engineering professionals and data scientists. If you'd like to use the platform for your own purposes, or find out about solutions we could build for you, get in touch with us at <a href="mailto:info@coinmarketmood.com">info@coinmarketmood.com</a>, or DM us on twitter <a href="https://twitter.com/coinmarketmood">@coinmarketmood</a>.</p>
        <Link to="/">Go back to the homepage</Link>
    </Layout>
)

export default ServicesPage
